import CalendarWorkTime from "./calendarWorkTime"
import { dateRange } from "./date"
import Slot from "./slot"
import SlotStorageDateBusyness from "./slotStorageDateBusyness"
import TimePeriod from "./timePeriod"

/**
 * Sorting fucn for the slots
 * @param {Slot} slotA - first slot
 * @param {Slot} slotB - second slot
 * @returns time difference between slots start date
 */
function sortByTime(slotA, slotB) {
	return slotA.period.start.time - slotB.period.start.time
}

class SlotStorage {
	/**
	* Sorted slots by date and time.
	* Example : {
		"2021-08-01": [slot1, slot2, ...],
		"2021-08-02": [slot3, slot4, ...],
	}
	*/
	sortedSlots = {}
	/**
	* Dates busyness by slots
	* Example : {
		"2021-08-01": SlotStorageDateBusyness,
		"2021-08-02": SlotStorageDateBusyness,
	}
	*/ 
	$datesBusyness = {}
	calendarWorkTime = null
	length = 0

	/**
	 * Create the slot storage instance that provide the basic slot storage functionality
	 * @param {Array} slots - initial array of slots
	 * @param {CalendarWorkTime} calendarWorkTime - calendar work time
	 */
	constructor(slots, calendarWorkTime) {
		this.calendarWorkTime = calendarWorkTime
		this.add(slots)
	}
	
	add(slots, withClearOld=false) {
		if (!slots || !slots.length)
			return
		if (withClearOld) {
			this.sortedSlots = {}
		}

		slots.forEach(slot => {
			const slotDate = slot.period.start.date
			if (!slotDate)
				return
			const slotDateTitle = slotDate.toLocaleDateString()
			if (!slotDateTitle)
				return 

			if (!this.sortedSlots[slotDateTitle])
				this.sortedSlots[slotDateTitle] = []

			// If slot already included
			const isIncluded = !this.sortedSlots[slotDateTitle].find(s => slot.index == s.index) 
			if (!isIncluded)
				return

			// Push slot to the date slots array and sort them by time
			this.sortedSlots[slotDateTitle].push(slot)
			this.sortedSlots[slotDateTitle] = this.sortedSlots[slotDateTitle].sort(sortByTime)

			this.length++
		})

		const datesTitles = slots
			.map(slot => slot.period.start.date.toLocaleDateString())
			.filter((value, index, self) => self.indexOf(value) === index)

		// Update dates busyness by slots
		this.$updateDatesBusyness(datesTitles)
	}

	findById(id) {
		return Object.values(this.sortedSlots)
			.find(dateSlots => dateSlots.find(slot => slot.id === id))
	}

	findByDate(date) {
		if (!date)
			return []
		const dateTitle = date.toLocaleDateString()
		return this.sortedSlots[dateTitle] || []
	}

	findByRange(from, to) {
		if (!from || !to)
			return []
		const dates = dateRange(from, to)
		return dates
			.map(date => this.findByDate(date))
			.flat() || []
	}

	remove(slots) {
		if (!slots || !slots.length)
			return
		
		// Sort slots by date to filter
		const slotsToFilter = {}
		slots.forEach(slot => {
			const slotDate = slot.period.start.date
			if (!slotDate)
				return
			const slotDateTitle = slotDate.toLocaleDateString()
			if (!slotDateTitle)
				return
			if (!slotsToFilter[slotDateTitle])
				slotsToFilter[slotDateTitle] = []

			slotsToFilter[slotDateTitle].push(slot)
		})

		// Remove slots date by date
		Object.keys(slotsToFilter).forEach(slotDateTitle => {
			const sorted = this.sortedSlots[slotDateTitle] 
			const toFilter = slotsToFilter[slotDateTitle]
			if (!sorted)
				return
			this.sortedSlots[slotDateTitle] = sorted.filter(slot => {
				return !toFilter.find(s => s.index == slot.index)
			})
			this.length -= toFilter.length
		})

		// Update dates busyness by slots
		this.$updateDatesBusyness(Object.keys(slotsToFilter))
	}

	resortSlots(date){
		const dateTitle = date.toLocaleDateString()

		if (this.sortedSlots[dateTitle])
			this.sortedSlots[dateTitle] = this.sortedSlots[dateTitle].sort(sortByTime)
		
		this.$updateDatesBusyness([dateTitle])
	}

	$updateDatesBusyness(datesTitles=null){
		if (!datesTitles) {
			datesTitles = Object.keys(this.sortedSlots) 
		}

		datesTitles.forEach(dateTitle => {
			const dateSlots = this.sortedSlots[dateTitle]
			if (!dateSlots)
				return
			if (!this.$datesBusyness[dateTitle]) {
				const date = new Date(dateSlots[0].period.start.date)
				date.setHours(0, 0, 0, 0)
				this.$datesBusyness[dateTitle] = new SlotStorageDateBusyness(date, this.calendarWorkTime)
			}
			this.$datesBusyness[dateTitle].update(this.sortedSlots[dateTitle])
		})
	}

	/**
	 * Check free time by time period
	 * @param {Object} param0 - timePeriod or pair date with duration
	 * @param {Array} escape - array of time periods to escape
	 * @returns 
	 */
	isBusyTime({timePeriod, date, duration}, escape=[]) {
		if (!timePeriod && !date || (date && !duration))
			return false

		
		if (date && duration) {
			timePeriod = new TimePeriod(date, -1, duration)
		}
		if (!timePeriod)
			return false
		
		const dateTitle = timePeriod.start.date.toLocaleDateString()
		const dateBusyness = this.$datesBusyness[dateTitle]
		if (!dateBusyness)
			return false
		return dateBusyness.isBusy(timePeriod, escape)
	}

	/** 
	 * Find the nearest free place to move the slot
	 * @param {TimePeriod} timePeriod - desired time period after slot move
	*/
	nearestFreePlace(timePeriod) {
		if (!timePeriod)
			return undefined

		const dateTitle = timePeriod.start.date.toLocaleDateString()
		const dateBusyness = this.$datesBusyness[dateTitle]
		if (!dateBusyness)
			return timePeriod

		return dateBusyness.calcNearestFreePlace(timePeriod)
	}
	/**
	 * Get slot neighbors
	 * @param {Slot} slot - slot to check
	 */
	getNeighbors(slot){
		if (!slot)
			return undefined
		const dateTitle = slot.period.start.date.toLocaleDateString()
		const dateBusyness = this.$datesBusyness[dateTitle]
		if (!dateBusyness)
			return undefined
		return dateBusyness.getNeighbors(slot.index)
	}
}

export default SlotStorage