import CalendarWorkTime from "./calendarWorkTime"
import { isEqualDate, isEqualTime } from "./date"
import { calcNearestPlace } from "./slotNearestPlace"
import TimePeriod, { sortPeriodsByTime } from "./timePeriod"

class SlotStorageDateBusyness {
	$date = null

	$neighbors = {} // Map of slots ids that has a neighbor lower than slot
	$busyTime = []
	$workTime = null

	$cacheEscapePeriods = []
	$cacheEscapedBusyTime = []

	$cacheCheckFreePlace = null
	$cacheFreePlace = null

	/**
	 * 
	 * @param {Date} date - date
	 * @param {CalendarWorkTime} workTime - work time 
	 */
	constructor(date, workTime) {
		if (!date)
			throw new Error("Date is required")
		if (!workTime)
			throw new Error("Work time is required")
		this.$date = date
		this.$workTime = workTime.projection(date)
		this.$busyTime = []
	}

	/**
	 * Update free time by slots
	 * @param {Array} slots - array of slots with the same date 
	 */
	update(slots) {
		if (!slots)
			slots = []

		// Take slots periods 
		const slotsPeriods = slots
			.map(slot => slot.period)
			.sort(sortPeriodsByTime)

		this.$neighbors = {}
		if (slotsPeriods.length == 1) {
			this.$busyTime = [slotsPeriods[0]]
			return
		}
		// Union periods that near or intersect to one period 
		const unitedSlotsPeriods = [ ]
		let tempPeriod = null
		let counter = 0
		while (counter < slotsPeriods.length) {

			const current = slotsPeriods[counter]
			const next = counter < slotsPeriods.length - 1 ?
				slotsPeriods[counter + 1] : undefined
			const prev = counter > 0 ?
				slotsPeriods[counter - 1] : undefined
			
			// Check if slot has neighbors and save it
			const neighbors = {top: undefined, bot:undefined}
			neighbors["top"] = prev && isEqualTime(prev.end.date, current.start.date) ? 
				prev.index : undefined
			neighbors["bot"] = next && isEqualTime(current.end.date, next.start.date) ?  
				next.index : undefined
			if (neighbors["top"] || neighbors["bot"]) {
				this.$neighbors[current.index] = neighbors
			}
				
			if (counter == 0) {
				counter++;
				continue
			}

			if (isEqualTime(prev.end.date, current.start.date)) {
				if (tempPeriod == null) {
					tempPeriod = new TimePeriod(prev.start.date, -1, undefined, current.end.date)
				} else { 
					tempPeriod.updateDuration(tempPeriod.duration + current.duration)
				}

				if (!next) {
					unitedSlotsPeriods.push(tempPeriod)
				}
			} else {
				if (tempPeriod != null) {
					unitedSlotsPeriods.push(tempPeriod)
					tempPeriod = null
				} else {
					unitedSlotsPeriods.push(prev)
				}

				if (!next) {
					unitedSlotsPeriods.push(current)
				}
			}
			counter++;
		}
		

		this.$busyTime = unitedSlotsPeriods
		this.clearCache()
	}

	/**
	 * Check time period is free or not
	 * @param {TimePeriod} timePeriod - time period to check
	 * @param {Array} escape - array of time periods to escape
	 */
	isBusy(timePeriod, escape=[]) {
		if (!timePeriod || !isEqualDate(this.$date, timePeriod.start.date))
			return false
		if (!this.$busyTime || !this.$busyTime.length)
			return false

		if (!this.$escapePeriodsIsEqual(escape)) {
			this.$cacheEscapePeriods = escape
			this.$cacheEscapedBusyTime = this.$calcEscapedBusyTime(escape)
		}

		if (escape && escape.length) {
			return !!this.$cacheEscapedBusyTime
			.find(busyPeriod => {
				return busyPeriod.intersection(timePeriod) 
					|| busyPeriod.isEqual(timePeriod) 
					|| timePeriod.include(busyPeriod.start.date)
					|| timePeriod.include(busyPeriod.end.date)
			})
		}
		
		return !!this.$busyTime
		.find(busyPeriod => {
			return busyPeriod.intersection(timePeriod) 
				|| busyPeriod.isEqual(timePeriod)
				|| timePeriod.include(busyPeriod.start.date)
				|| timePeriod.include(busyPeriod.end.date)
		})
	}

	clearCache(){
		this.$cacheEscapePeriods = []
		this.$cacheEscapedBusyTime = []
		this.$cacheCheckFreePlace = null
		this.$cacheFreePlace = null
	}

	$escapePeriodsIsEqual(escape) {
		if (this.$cacheEscapePeriods.length != escape.length)
			return false

		const length = this.$cacheEscapePeriods.length
		for (let i = 0; i < length; i++) {
			const cachedPeriod = this.$cacheEscapePeriods[i]
			const newPeriod = escape[i]
			if (!cachedPeriod.isEqual(newPeriod))
				return false
		}
		return true
	}

	$calcEscapedBusyTime(escape) {
		if (!escape || !escape.length)
			return this.$busyTime
		let busyTimeCopy = this.$busyTime.map(busyPeriod => busyPeriod.copy())
		
		busyTimeCopy.forEach(busyPeriod => {
			console.log('\t - ', busyPeriod.toString());
		})

		let isPeriodAdded = false
		
		escape.forEach(escapePeriod => {
			busyTimeCopy.forEach((busyPeriod, index) => {

				if (busyPeriod == null)
					return

				if (busyPeriod.isEqual(escapePeriod)) {
					busyTimeCopy[index] = null
					isPeriodAdded = true
					return
				}

				// If escape period is inside busy period
				// Split period on two with extract escape period
				if (busyPeriod.include(escapePeriod.start.date) 
				&& busyPeriod.include(escapePeriod.end.date)) {
					const splitPeriods = busyPeriod.splitByPeriod(escapePeriod)
					busyTimeCopy.push(...splitPeriods)
					busyTimeCopy[index] = null
					isPeriodAdded = true

				// If only start of escape period is inside busy period
				// Change duration of busy period
				} else if (busyPeriod.include(escapePeriod.start.date)) {
					const delta = (escapePeriod.start.time - busyPeriod.end.time) / 1000 / 60
					busyPeriod.updateDuration(busyPeriod.duration + delta)
					if (busyPeriod.duration <= 0) {
						busyTimeCopy[index] = null
						isPeriodAdded = true	
					}

				// If only end of escape period is inside busy period 
				// Change duration of busy period and shift it
				} else if (busyPeriod.include(escapePeriod.end.date)) {
					const delta = (busyPeriod.start.time - escapePeriod.end.time) / 1000 / 60
					
					busyPeriod.updateDuration(busyPeriod.duration + delta)
					busyPeriod.shift(delta * -1)
					if (busyPeriod.duration <= 0) {
						busyTimeCopy[index] = null
						isPeriodAdded = true	
					}
				}
			})	
		})

		if (isPeriodAdded) {
			busyTimeCopy = busyTimeCopy
				.filter(period => !!period)
				.sort(sortPeriodsByTime)
		}

		console.log('calc escaped busy time:>> ');
		busyTimeCopy.forEach(busyPeriod => {
			console.log('\t - ', busyPeriod.toString());
		})

		return busyTimeCopy
	}

	/**
	* Calculate the nearest free place to move the slot
	* @param {TimePeriod} timePeriod - desired time period after slot move
	* @returns {TimePeriod} - nearest free place
	*/
	calcNearestFreePlace(timePeriod) {
		if (!this.$cacheCheckFreePlace 
		|| !this.$cacheCheckFreePlace.isEqual(timePeriod)) {
			this.$cacheCheckFreePlace = timePeriod.copy()
			const cachedBusyPeriods = this.$cacheEscapedBusyTime 
			const busyPeriods = cachedBusyPeriods && cachedBusyPeriods.length  ?
				this.$cacheEscapedBusyTime : this.$busyTime
			this.$cacheFreePlace = calcNearestPlace(timePeriod, busyPeriods, this.$workTime)
		}

		return this.$cacheFreePlace
	}

	/**
	 * Get slot neighbors
	 * @param {Number} slotId - slot id
	 * @returns boolean - true if slot has neighbors
	 * @returns boolean - false if slot has no neighbors
	 */
	getNeighbors(slotId) {
		return this.$neighbors[slotId] 
	}
}

export default SlotStorageDateBusyness