import Vue from "vue"
import CMS from "../service/cms/service"
import Attender from "./attender"
import CalendarWorkDays from "./calendarWorkDays"
import CalendarWorkTime from "./calendarWorkTime"
import { ADMIN_MEMBERSHIP_LEVEL, 
		FOLLOWER_MEMBERSHIP_LEVEL, 
		MANAGER_MEMBERSHIP_LEVEL, 
		NONE_MEMBERSHIP_LEVEL, 
		OWNER_MEMBERSHIP_LEVEL } from "./membershipLevels"
import SlotCollection from "./slotCollection"

class Calendar {
	id
	uid
	title
	description

	timezoneId
	timezoneName
	workdayCalendarId
	visibilityLevelId
	membershipLevel

	isCommercial = false
	isRestricted = false
	isEditable = false

	workTime = null // CalendarWorkTime
	workDays = null
	slotCollection = null // Slot storage for calendar
	attenders = []
	settings

	workDaysIsLoaded = false
	attendersIsLoaded = false

	// If calendar is restricted
	suggestedAction = null

	constructor(data, isRestricted = false) {
		this.id = data["id"]
		this.uid = data["uid"]
		this.timezoneId = data["timezoneId"]
		this.timezoneName = data["timezoneName"]
		this.visibilityLevelId = data["visibilityLevelId"]
		this.title = data["title"] ?? ""
		this.description = data["description"] ?? ""
		this.isCommercial = data["isCommercial"] ?? false
		this.membershipLevel = data["membershipLevel"]
		this.isRestricted = isRestricted ?? false
		this.settings = data
		this.suggestedAction = data["suggestedAction"] ?? null
		
		this.setupCalendarPermissions()
		
		if (!this.isRestricted) {
			this.setupWorkTime(data["from"], data["to"])
			this.setupWorkDays(data["workdayCalendarId"])
			this.setupAttenders()
			this.slotCollection = new SlotCollection(this.uid, [], this.workTime)
		}
	}

	setupAttenders(){
		if (!this.isEditable)
			return

		CMS.attenders.list(this.uid)
		.then(data => {
			if (data && data.err)
				throw data.err
		
			let attenders = []
			if (data.active) {
				data.active.forEach(attender => {
					attenders.push(new Attender(attender))
				})
			}
			let isDisabled = true
			if (data.archive) {
				data.archive.forEach(attender => {
					attenders.push(new Attender(attender, isDisabled))
				})
			}
			this.attenders = attenders
		}).finally(() => Vue.set(this, "attendersIsLoaded", true))
	}

	setupWorkTime(from, to){
		const startHour = Math.floor(from / 60)
		const startMinute = from - Math.floor(from / 60) * 60
		const startDate = new Date()
		startDate.setHours(startHour)
		startDate.setMinutes(startMinute)
	
		const endHour = Math.floor(to / 60)
		const endMinute = to - Math.floor(to / 60) * 60
		const endDate = new Date()
		endDate.setHours(endHour)
		endDate.setMinutes(endMinute)
		
		this.settings.to = to
		this.settings.from = from
		this.workTime = new CalendarWorkTime(startDate, endDate, !this.isCommercial)
	}

	setupWorkDays(workdaysCalendarId){
		this.workdayCalendarId = workdaysCalendarId
		this.settings.workCalendarId = this.workdayCalendarId
		CMS.geo.workdayCalendar.getByID(workdaysCalendarId)
		.then(data => {
			if (data.err) 
				throw data.err

			this.workDays = new CalendarWorkDays(data.weekends, data.special)
		})
		.catch((err) => {console.error(err)})
		.finally(() => Vue.set(this, "workDaysIsLoaded", true))
	}

	getTimezone(){
		return {
			id: this.timezoneId,
			name: this.timezoneName
		}
	}

	setupCalendarPermissions(){
		if (this.membershipLevel === OWNER_MEMBERSHIP_LEVEL 
		|| this.membershipLevel === MANAGER_MEMBERSHIP_LEVEL 
		|| this.membershipLevel === ADMIN_MEMBERSHIP_LEVEL)
			this.isEditable = true
	}

	update(data){

		if (data["title"] && data["title"] !== this.title) {
			this.title = data["title"]
			this.settings.title = this.title
		}
		if (data["description"] !== this.description) {
			this.description = data["description"]
			this.settings.description = this.description
		}
		if (data["workCalendarId"] && data["workCalendarId"] !== this.workdayCalendarId){
			this.setupWorkDays(data["workCalendarId"])
		}
		if (data["from"] != this.settings["from"] && data["to"] != this.settings["to"]){
			this.setupWorkTime(data["from"], data["to"])
		}
		if (data["isCommercial"] !== this.isCommercial) {
			this.isCommercial = data["isCommercial"]
			this.settings.isCommercial = this.isCommercial
		}
		if (data["countryId"] && data["countryId"] !== this.settings.countryId) {
			this.settings.countryId = data["countryId"]
		}
		if (data["timezone"] && data["timezone"].id !== this.timezoneId) {
			this.timezoneId = data["timezone"].id
			this.timezoneName = data["timezone"].name
			this.settings.timezoneId = this.timezoneId
			this.settings.timezoneName = this.timezoneName
		}
		if (data["visibilityLevelId"] && data["visibilityLevelId"] !== this.visibilityLevelId) {
			this.visibilityLevelId = data["visibilityLevelId"]
			this.settings.visibilityLevelId = this.visibilityLevelId
		}
	}

	isPublicForUser(){
		return lastCalendarVisibility == 2 
			|| lastCalendarVisibility == 3
	}

	getAttendersByIDs(userIds=[]){
		if (!userIds || userIds.length == 0)
			return []
		return this.attenders.filter(attender => userIds.includes(attender.userId))
	}
}

export default Calendar