class SlotCollectionUsers {
	
	$attenders = []
	$hosts = []

	constructor() {
		this.$attenders = []
		this.$hosts = []
	}

	findAttenderById(id) {
		return this.$attenders.find(attender => attender.id === id)
	}

	findHostById(id) {
		return this.$hosts.find(host => host.id === id)
	}

	addAttenders(attenders) {
		if (!attenders || !attenders.length)
			return
		attenders.forEach(attender => {
			const isAttended = this.findAttenderById(attender.id)
			if (!isAttended)
				this.$attenders.push(attender)
		})
	}
	
	addHosts(hosts) {
		if (!hosts || !hosts.length)
			return
		hosts.forEach(host => {
			const isHost = this.findHostById(host.id)
			if (!isHost)
				this.$hosts.push(host)
		})
	}
}

export default SlotCollectionUsers;